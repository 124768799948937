import { PendoUserData } from '../../fetch-services/user.service.types';

declare global {
  interface Window {
    pendo: {
      initialize: (config: any) => void;
    };
  }
}

export const initializePendo = (pendoUserData: PendoUserData) => {
  if (window.pendo && window.pendo.initialize) {
    window.pendo.initialize({
      visitor: {
        id: pendoUserData.userGuid,
        email: pendoUserData.email,
        full_name: `${pendoUserData.firstName} ${pendoUserData.lastName}`,
        role: pendoUserData.roleName,
        account: {
          id: pendoUserData.organizationDetails[0].institutionId,
          siteCode: pendoUserData.siteOrganizationDetails.siteCode,
          siteInstitutionName:
            pendoUserData.siteOrganizationDetails.siteInstitutionName,
          siteInstitutionId:
            pendoUserData.siteOrganizationDetails.siteInstitutionId,
          siteInstitutionState:
            pendoUserData.siteOrganizationDetails.siteInstitutionState,
          siteInstitutionCategory:
            pendoUserData.siteOrganizationDetails.siteInstitutionOrgType,
          siteInstitutionALAType:
            pendoUserData.siteOrganizationDetails.siteInstitutionAlaType,
          parentInstitutionName:
            pendoUserData.organizationDetails[0].parentInstitutionName,
          parentInstitutionId:
            pendoUserData.organizationDetails[0].parentInstitutionId,
          parentInstitutionState:
            pendoUserData.organizationDetails[0].parentInstitutionState,
          parentInstitutionCategory:
            pendoUserData.organizationDetails[0].parentInstitutionOrgType,
          parentInstitutionALAType:
            pendoUserData.organizationDetails[0].parentInstitutionAlaType,
          userInstitutionCategory: pendoUserData.organizationDetails
            .map((org) => org.institutionOrgType)
            .join(','),
          userInstituionALAType: pendoUserData.organizationDetails
            .map((org) => org.institutionAlaType)
            .join(','),
          userInstitutionName: pendoUserData.organizationDetails
            .map((org) => org.institutionName)
            .join(','),
          userInstitutionID: pendoUserData.organizationDetails
            .map((org) => org.institutionId)
            .join(','),
          userInstitutionState: pendoUserData.organizationDetails
            .map((org) => org.institutionState)
            .join(','),
          assessmentProviders: pendoUserData.productsLicensed?.length
            ? pendoUserData.productsLicensed
                .filter((product) => product.includes('IPA'))
                .join(',')
            : '',
          productsLicensed: pendoUserData.productsLicensed?.length
            ? pendoUserData.productsLicensed
            : 'none',
          Spanish_Lang_Math: pendoUserData.institutionSettingsDetails
            .map((settings) =>
              settings.spanishLanguageMath ? 'Enabled' : 'Disabled'
            )
            .join(','),
          Spanish_Lang_Science: pendoUserData.institutionSettingsDetails
            .map((settings) =>
              settings.spanishLanguageScience ? 'Enabled' : 'Disabled'
            )
            .join(','),
          academicSessions: pendoUserData.academicSessionsResponse
            .flat()
            .map(
              (aca) =>
                `${aca.title} startDate ${aca.startDateFormat.replaceAll(
                  '/',
                  '-'
                )} endDate ${aca.endDateFormat.replaceAll('/', '-')}`
            )
            .join(',')
        }
      }
    });
  }
};
