import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from '../../fetch-services/user.service.types';
import { Organization } from '../../fetch-services/org.service.types';

type UserState = {
  userInfo: UserInfo | null;
  mainOrg: Organization | null;
};

const initialState: UserState = {
  userInfo: null,
  mainOrg: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
    },
    setMainOrg(state, action: PayloadAction<Organization | null>) {
      state.mainOrg = action.payload;
    }
  }
});

export const { setUser, setMainOrg } = userSlice.actions;

export default userSlice.reducer;
