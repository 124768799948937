export enum Role {
  DistrictAdmin = 3,
  SchoolAdmin = 4,
  Teacher = 5
}

export enum SidebarLabel {
  Home = 'Home',
  Institutions = 'Institutions',
  District = 'District',
  School = 'School',
  Classes = 'Classes',
  Users = 'Users',
  Students = 'Students',
  Scheduling = 'Scheduling',
  AcademicSessions = 'Academic Sessions',
  Formatives = 'Formatives',
  Settings = 'Settings',
  Reports = 'Reports'
}

export enum SidebarLink {
  Home = '/home',
  Institutions = '/institutions',
  District = '/district',
  School = '/school',
  Classes = '/classes',
  Users = '/users',
  Students = '/students',
  Scheduling = '/scheduling',
  AcademicSessions = '/academicsessions',
  Formatives = '/assignmentbuilder/dashboard',
  Settings = '/settings',
  Reports = '/reports'
}

export enum OrgGroupLevel {
  District = 'district',
  School = 'school',
  Class = 'class'
}

export enum Products {
  IPA = 'IPA',
  IPA_MATH = 'IPA_Math',
  IPA_ELA = 'IPA_ELA'
}
