import React, { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import { UserInfo } from '../../fetch-services/user.service.types';

export type IpaHomeProps = {
  userBehaviorSubject?: BehaviorSubject<UserInfo>;
};

const IpaHome = (props: IpaHomeProps) => {
  const { userBehaviorSubject } = props;
  const { userInfo } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    store.dispatch(setPageTitle(userInfo?.organizations[0].name || ''));
    if (userBehaviorSubject && userInfo) {
      userBehaviorSubject.next(userInfo);
    }
  }, [userBehaviorSubject, userInfo]);

  return <div id="ipa-educator-home"></div>;
};

export default IpaHome;
