import React, { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import { Organization } from '../../fetch-services/org.service.types';

export type AssessmentSchedulerProps = {
  organizationBehaviorSubject?: BehaviorSubject<Organization>;
};

const AssessmentScheduler = (props: AssessmentSchedulerProps) => {
  const { organizationBehaviorSubject } = props;
  const { mainOrg } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    store.dispatch(setPageTitle('Scheduling'));
    if (organizationBehaviorSubject && mainOrg) {
      organizationBehaviorSubject.next(mainOrg);
    }
  }, [organizationBehaviorSubject, mainOrg]);

  return <div id="assessment-scheduler"></div>;
};

export default AssessmentScheduler;
