import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReportsProps } from './reports.props';
import { SidebarLink } from '../../common/enums';
import { Breadcrumbs } from '@wne/mpng-components';

const mockBreadcrumbs = [
  { name: 'District', callback: () => {}, isCurrentPage: false },
  { name: 'School', callback: () => {}, isCurrentPage: false },
  { name: 'Class', callback: () => {}, isCurrentPage: true }
];

const Reports = (props: ReportsProps) => {
  const { level, orgGuid } = props;
  const navigate = useNavigate();

  const handleDiagnosticClick = () => {
    navigate(`/mypath-assessment-reports/${level.toLowerCase()}/${orgGuid}`);
  };

  const handleFormativesClick = () => {
    navigate(SidebarLink.Formatives);
  };

  return (
    <>
      <Breadcrumbs breadcrumbs={mockBreadcrumbs} />
      <div>
        <button onClick={handleDiagnosticClick}>Diagnostic Report</button>
      </div>
      <div>
        <button onClick={handleFormativesClick}>Formatives Dashboard</button>
      </div>
    </>
  );
};

export default Reports;
