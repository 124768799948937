import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  useLayoutEffect(() => navigate('home'), [navigate]);

  return <></>;
};

export default Home;
