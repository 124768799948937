import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SidebarLabel } from '../../common/enums';

type AppState = {
  pageTitle: string | null;
  activeNavItem: SidebarLabel | null;
  activityIndicator: boolean;
};

const initialState: AppState = {
  pageTitle: null,
  activeNavItem: null,
  activityIndicator: false
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPageTitle(state, action: PayloadAction<string>) {
      state.pageTitle = action.payload;
    },
    setActiveNavItem(state, action: PayloadAction<SidebarLabel>) {
      state.activeNavItem = action.payload;
    },
    setActivityIndicator(state, action: PayloadAction<boolean>) {
      state.activityIndicator = action.payload;
    }
  }
});

export const { setPageTitle, setActiveNavItem, setActivityIndicator } = appSlice.actions;

export default appSlice.reducer;
