import React, { useCallback, useEffect, useState } from 'react';
import { autoRenewToken } from '../../auth-service/auth-service';
import store from '../../redux-store/store';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import Routing from '../routing/routing';
import useOidcAuthentication from '../../hooks/use-oidc-authentication';
import { UserService } from '../../fetch-services/user.service';
import { setUser } from '../../redux-store/slices/user-slice';
import { EducatorUiProps } from '../../../../root-config/src/shared/educator-ui.props';
import { initializePendo } from './pendo-initializer';

interface ImlpJwtPayload extends JwtPayload {
  tenant_user_id: string;
}

const App = (props: EducatorUiProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { accessTokenBehaviorSubject } = props;
  const authState = useOidcAuthentication();

  if (authState?.user) {
    autoRenewToken(accessTokenBehaviorSubject!);
    accessTokenBehaviorSubject!.next(authState?.user.access_token);
  }

  const runInitializePendo = useCallback((pendoUserData) => {
    if (pendoUserData) {
      initializePendo(pendoUserData);
    }
  }, []);

  useEffect(() => {
    const getUserInfo = async (userGuid: string) => {
      const userInfo = await UserService.getUserInfo(userGuid);
      const pendoUserData = await UserService.getPendoUserData(userGuid);
      if (userInfo) {
        store.dispatch(setUser(userInfo));
        runInitializePendo(pendoUserData);
        setIsLoading(false);
      }
    };

    if (authState?.user?.access_token) {
      const userGuid = jwtDecode<ImlpJwtPayload>(
        authState?.user?.access_token
      ).tenant_user_id;
      getUserInfo(userGuid);
    }
  }, [authState?.user?.access_token, runInitializePendo]);

  const handleToastClose = () => {
    window.location.href = `${window.APP_ENV.ulpRedirectUrl}`;
  };

  return (
    <div>
      {isLoading && <section>Loading</section>}
      {!isLoading && <Routing {...props} />}
    </div>
  );
};

export default App;
