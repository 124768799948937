import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import RootComponent from './root.component';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: RootComponent,
  errorBoundary(_: any, __: any, ___: any) {
    return <div>Error occurred rendering Assessment Educator-UI MFE.</div>;
  }
});

export const { bootstrap, mount, unmount } = lifecycles;
