import React from 'react';
import { Provider } from 'react-redux';
import store from './redux-store/store';
import App from './containers/app/app';
import { EducatorUiProps } from '../../root-config/src/shared/educator-ui.props';
import './styles/font.scss';
import './styles/base.scss';

System.import('@wne/mpng-components');

const RootComponent = (props: EducatorUiProps) => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <App {...props} />
      </Provider>
    </React.StrictMode>
  );
};

export default RootComponent;
