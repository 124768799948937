import { User } from 'oidc-client-ts';
import { useEffect, useState } from 'react';
import { getUser, login, signinCallback } from '../auth-service/auth-service';

const useOidcAuthentication = () => {
  const [authState, setAuthState] = useState<{
    user: User | null;
    error: string | null;
  }>();

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        let oidcUser = await getUser();
        if (!oidcUser) {
          const params = new URLSearchParams(window.location.search);
          if (params.get('path') === 'login-callback') {
            const url = window.location.href.replace('path=login-callback', '');
            oidcUser = (await signinCallback(url)) as User | null;
          } else {
            await login();
          }
        }

        if (oidcUser) {
          if (location.href.includes('?path=login-callback&code=')) {
            history.pushState({}, document.title, location.href.split('?')[0]);
          }
          setAuthState({ user: oidcUser, error: null });
        }

        if (!oidcUser) {
          const error = 'Error during authenticateUser';
          console.error(error);
          setAuthState({ user: null, error });
        }
      } catch (error) {
        console.error('Error during authenticateUser:', error);
        setAuthState({ user: null, error: error as string });
      }
    };

    authenticateUser();
  }, []);

  return authState;
};

export default useOidcAuthentication;
