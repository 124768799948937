import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import store from '../../redux-store/store';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { SearchSubmit, PagedNavTable, MpngExportReport, SearchHelpMessage } from '@wne/mpng-components';
import { UserService } from '../../fetch-services/user.service';
import { Paged, Educator, EducatorSearchRequest } from '../../fetch-services/user.service.types';
import { OrgGroupLevel } from '../../common/enums';
import { getReportFilename } from '../../common/utils';
import { EducatorsProps } from './educators.props';
import './educators.scss';

const emptyPage: Paged<Educator> = {
  size: 0,
  page: 0,
  pageSize: 0,
  values: []
};

const pageSizeOptions = [50, 100, 250, 500];

const Educators = (props: EducatorsProps) => {
  const { orgGuid, orgLevel, orgName, userGuid } = props;
  const [educatorsPagedData, setEducatorsPagedData] = React.useState<Paged<Educator>>(emptyPage);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
  const [searchText, setSearchText] = React.useState('');
  const [searchClassName, setSearchClassName] = React.useState('educators-placeholder');
  const [sortColumn, setSortColumn] = React.useState('LastName');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');

  const searchTextRef = useRef('');

  const navigate = useNavigate();

  let educatorColumnDetails =
  [
    {
      name: 'Educator Name',
      getColumnData: (data: Educator) => `${data.lastName}, ${data.firstName}`,
      alignment: 'left',
      sortProperty: 'lastname',
      onLinkSelect: (data: Educator) => navigate(`/educator/${data.userGuid}`)
    },
    {
      name: 'Username/Email',
      getColumnData: (data: Educator) => data.loginName,
      sortProperty: 'loginname',
      alignment: 'left'
    },
    {
      name: 'Institution',
      getColumnData: (data: Educator) => data.organizations.join(', '),
      sortProperty: 'organizations',
      alignment: 'left'
    },
    {
      name: 'External ID',
      getColumnData: (data: Educator) => data.districtAssignedId,
      alignment: 'left'
    },
    {
      name: 'Educator Type',
      getColumnData: (data: Educator) => data.roleName,
      sortProperty: 'rolename',
      alignment: 'left'
    }
  ];

  if (orgLevel !== OrgGroupLevel.District) { //remove school column if not in a district
    educatorColumnDetails = educatorColumnDetails.filter((column) => column.name !== 'Institution');
  };

  const educatorsCSVColumns = [
    {
      label: "Educator Name",
      id: "educatorName"
    },
    {
      label: "Username/Email",
      id: "userName"
    },
    {
      label: "Institution",
      id: "institution"
    },
    {
      label: "External ID",
      id: "externalID"
    },
    {
      label: "Educator Type",
      id: "educatorType"
    }
  ];

  const getEducatorSearchRequest = (): EducatorSearchRequest => {
    const request: EducatorSearchRequest = {
      searchText: searchText,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      orgGuid: orgGuid,
      page: page,
      pageSize: pageSize,
      userGuid: userGuid
    };
    return request;
  };

  const fetchEducators = async () => {
    store.dispatch(setActivityIndicator(true));
    const response = await UserService.getEducatorsByDistrict(getEducatorSearchRequest());
    if (response) {
      setEducatorsPagedData(response);
    }
    store.dispatch(setActivityIndicator(false));
  };

  const fetchEducatorsForCSV = async (csvCallback: (data: any) => void) => {
    store.dispatch(setActivityIndicator(true));
    const response = await UserService.getEducatorsByDistrictCSV(getEducatorSearchRequest());
    if (response) {
      csvCallback({rows: response, columns: educatorsCSVColumns});
    }
    store.dispatch(setActivityIndicator(false));
  };

  useEffect(() => {
    fetchEducators();
  }, [orgGuid, searchText, sortColumn, sortDirection, page, pageSize]);

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchTextRef.current = e.target.value;
    if (e.target.value === '') {
      setSearchClassName('educators-placeholder');
    } else if (searchClassName === 'educators-placeholder') {
      setSearchClassName('');
    }
  };

  const onSearch = () => {
    setSearchText(searchTextRef.current);
    setPage(1);
  };

  const onClearSearch = (fetchResults: boolean) => {
    if (fetchResults) {
      setSearchText('');
      setPage(1);
    }
    searchTextRef.current = '';
    setSearchClassName('educators-placeholder');
  };

  const onSortChange = (sortProperty: string) => {
    if (sortColumn === sortProperty) {
      setSortDirection((prev) => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(sortProperty);
      setSortDirection('asc');
    }
    setPage(1);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <div className='educators-search'>
      <div className='educators-search-header'>
        <SearchSubmit 
          placeHolder='Search Educator Name, Username/Email, External ID'
          className={searchClassName}
          onChange={onSearchInputChange}
          onSearch={onSearch}
          onClear={onClearSearch}
        />
        <MpngExportReport
          reportName={getReportFilename('Educators', orgName)}
          includePDF={false}
          mpngStyle={false}
          csvCallback={fetchEducatorsForCSV}
        />
      </div>
      <PagedNavTable
        columnDetails={educatorColumnDetails}
        tableData={educatorsPagedData.values}
        emptyTableContent={<SearchHelpMessage />}
        tableHeight='calc(100vh - 178px)'
        includePagination={true}
        totalItemsCount={educatorsPagedData.size}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        sortChange={onSortChange}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        pageNumber={page}
        pageChange={(page: number) => setPage(page)}
        pageSizeChange={onPageSizeChange}
        className='educators-table'
      />
    </div>
  );
};

export default Educators;
