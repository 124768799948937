import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setMainOrg } from '../../redux-store/slices/user-slice';
import {
  setActiveNavItem,
  setPageTitle
} from '../../redux-store/slices/app-slice';
import { Organization } from '../../fetch-services/org.service.types';
import { getSidebarItemByLink } from '../../common/sidebar-item';
import { PagedNavTable } from '@wne/mpng-components';

const Institutions = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const orgs = userInfo?.organizations!;
  store.dispatch(setPageTitle('Institutions'));

  const handleOrgClick = (org: Organization) => {
    store.dispatch(setMainOrg(org));

    const path = searchParams.get('link');
    if (path) {
      if (
        path?.includes('assignmentbuilder') ||
        path?.includes('reports') ||
        path?.includes('diagnostic-report')
      ) {
        navigate(`/${path}`);
      } else {
        navigate(`/${path}/${org.orgGuid}`);
      }
      const sidebarItem = getSidebarItemByLink(path);
      if (sidebarItem) {
        store.dispatch(setActiveNavItem(sidebarItem.label));
      }
    }
  };

  let mockOrgs = [...orgs];

  let columnDetails: any[] = [
    {
      name: 'Institution',
      isSorted: false,
      isLink: true,
      onLinkSelect: (data: any) => handleOrgClick(data),
      getColumnData: (data: any) => data.name,
      alignment: 'left',
      getTooltipContent: (data: any) => 'breadbrumb/crumb/' + data.name
    },
    {
      name: 'Special tooltip',
      isSorted: true,
      getColumnData: (data: any) => data.name,
      alignment: 'left',
      getTooltipContent: (data: any) => 'breadbrumb/crumb/' + data.name
    },
    {
      name: 'Sort plus info',
      isSorted: true,
      getColumnData: (data: any) => data.name,
      alignment: 'right',
      infoIconContent: 'tooltip stuff for info icon'
    }
  ];

  mockOrgs.push({
    name: "super long orgname to make it overflow for testing but hey it wasn't long enough yet"
  } as Organization);

  for (let i = 0; i < 300; i++) {
    mockOrgs.push({ name: 'org - ' + i.toString() } as Organization);
    if (i < 3) {
      columnDetails.push({
        name: 'Column No.' + i.toString(),
        isSorted: i % 2 === 0,
        iconInfoContent: i % 2 === 0 ? 'tooltip stuff' : undefined,
        getColumnData: (data: any) => data.name,
        alignment: i % 2 !== 0 ? 'left' : 'right'
      });
    }
  }

  const [sortColumn, setSortColumn] = React.useState('Column No.2');
  const [sortDirection, setSortDirection] = React.useState('asc');
  const [pageSize, setPageSize] = React.useState(5);
  const [pageNumber, setPageNumber] = React.useState(1);
  const pageOptions = [1, 5, 10, 25, 50, 100];

  const changesort = (col: string) => {
    if (col === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(col);
      setSortDirection('asc');
    }
  };

  return (
    <PagedNavTable
      title="Institutions"
      columnDetails={columnDetails}
      tableData={mockOrgs.slice(
        (pageNumber - 1) * pageSize,
        pageNumber * pageSize
      )}
      tableHeight={'calc(100vh - 100px)'}
      includePagination={true}
      totalItemsCount={mockOrgs.length}
      pageSize={pageSize}
      pageSizeOptions={pageOptions}
      pageNumber={pageNumber}
      pageChange={(page: number) => setPageNumber(page)}
      pageSizeChange={(page: number) => {
        setPageSize(page);
      }}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      sortChange={changesort}
    />
  );
};

export default Institutions;
