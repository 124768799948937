import React from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import { Role } from '../../common/enums';

const Profile = () => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  store.dispatch(setPageTitle('Profile'));

  return (
    <div>
      <h4>Educator Profile</h4>
      <div>
        <div>Name</div>
        <div>
          {userInfo?.firstName} {userInfo?.lastName}
        </div>
      </div>
      <div>Username/Email</div>
      <div>{userInfo?.username}</div>
      <div>
        <div>Associated Schools</div>
        {userInfo?.organizations.map((org) => (
          <div key={org.name}>{org.name}</div>
        ))}
      </div>
      <div>
        <div>Type</div>
        {userInfo?.role ? Role[userInfo?.role] : ''}
      </div>
      <div>
        <div>External ID</div>
        {userInfo?.districtAssignedId}
      </div>
    </div>
  );
};
export default Profile;
