import React, { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import { FormativesType } from '../../../../root-config/src/shared/formatives.types';

export type FormativesProps = {
  formativesBehaviorSubject?: BehaviorSubject<FormativesType>;
};

const Formatives = (props: FormativesProps) => {
  const { formativesBehaviorSubject } = props;
  const { mainOrg } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    store.dispatch(setPageTitle('Formatives Dashboard'));
    if (formativesBehaviorSubject && mainOrg) {
      formativesBehaviorSubject.next({
        schoolId: mainOrg!.orgId,
        schoolGuid: mainOrg!.orgGuid,
        timeZone: mainOrg!.timezone,
        organization: mainOrg!,
        crumbs: [
          { path: '/assignmentbuilder/dashboard', name: 'Formatives Dashboard' }
        ]
      });
    }
  }, [formativesBehaviorSubject, mainOrg]);

  return <div id="assignment-builder"></div>;
};

export default Formatives;
