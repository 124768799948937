import React from 'react';
import { Outlet } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setMainOrg } from '../../redux-store/slices/user-slice';
import Header from '../../components/header/header';
import Sidebar from '../../components/sidebar/sidebar';
import { SidebarLink } from '../../common/enums';
import { SidebarRoutesWithGuid } from '../../common/constants';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo, mainOrg } = useSelector((state: RootState) => state.user);

  const multiOrgPages = [
    SidebarLink.Institutions,
    SidebarLink.Home,
    '/profile',
    '/'
  ];

  const path = location.pathname;
  if (userInfo?.organizations && !mainOrg) {
    if (userInfo.organizations.length === 1) {
      store.dispatch(setMainOrg(userInfo.organizations[0]));
      if (SidebarRoutesWithGuid.includes(path)) {
        navigate(`${path}/${userInfo.organizations[0].orgGuid}`);
      }
    } else if (
      !multiOrgPages.includes(path) &&
      userInfo.organizations.length > 1
    ) {
      navigate(`${SidebarLink.Institutions}?link=${path.replace('/', '')}`);
    }
  }

  return (
    <>
      <Header />
      <Sidebar />
      <div
        style={{
          marginLeft: '87px',
          marginTop: '59px',
          borderTop: '1px solid transparent'
        }}
      >
        <Outlet />
      </div>
    </>
  );
};

export default Navigation;
