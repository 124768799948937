import { SidebarLabel, SidebarLink } from './enums';
import { IconEnum, IconLibraryEnum } from '@wne/mpng-components';

export type SidebarItem = {
  label: SidebarLabel;
  link: SidebarLink;
  icon: IconEnum;
  iconLibrary?: IconLibraryEnum;
  selectedIcon?: IconEnum;
};

export const SidebarItems: { [key: string]: SidebarItem } = {
  home: {
    label: SidebarLabel.Home,
    link: SidebarLink.Home,
    icon: IconEnum.HOUSE
  },
  institutions: {
    label: SidebarLabel.Institutions,
    link: SidebarLink.Institutions,
    icon: IconEnum.BUILDING
  },
  district: {
    label: SidebarLabel.District,
    link: SidebarLink.District,
    icon: IconEnum.BUILDING
  },
  school: {
    label: SidebarLabel.School,
    link: SidebarLink.School,
    icon: IconEnum.BUILDING
  },
  classes: {
    label: SidebarLabel.Classes,
    link: SidebarLink.Classes,
    icon: IconEnum.BOOK_OPEN_COVER
  },
  users: {
    label: SidebarLabel.Users,
    link: SidebarLink.Users,
    icon: IconEnum.USERS
  },
  students: {
    label: SidebarLabel.Students,
    link: SidebarLink.Students,
    icon: IconEnum.GRADUATION_CAP
  },
  scheduling: {
    label: SidebarLabel.Scheduling,
    link: SidebarLink.Scheduling,
    icon: IconEnum.CALENDAR
  },
  academicSessions: {
    label: SidebarLabel.AcademicSessions,
    link: SidebarLink.AcademicSessions,
    icon: IconEnum.CALENDAR_DAYS
  },
  formatives: {
    label: SidebarLabel.Formatives,
    link: SidebarLink.Formatives,
    icon: IconEnum.MEMO_CIRCLE_CHECK
  },
  settings: {
    label: SidebarLabel.Settings,
    link: SidebarLink.Settings,
    icon: IconEnum.GEAR
  },
  reports: {
    label: SidebarLabel.Reports,
    link: SidebarLink.Reports,
    icon: IconEnum.CHART_LINE,
    iconLibrary: IconLibraryEnum.SOLID,
    selectedIcon: IconEnum.CHART_AREA
  }
};

export const getSidebarItemByLink = (link: string): SidebarItem | null => {
  for (const key in SidebarItems) {
    if (SidebarItems[key as keyof typeof SidebarItems].link === link) {
      return SidebarItems[key as keyof typeof SidebarItems];
    }
  }
  return null;
};
