import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'oidc-client-ts';

type AuthState = {
  isAuthenticated: boolean;
  user: User | null;
};

const initialState: AuthState = {
  isAuthenticated: false,
  user: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<User | null>) {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    },
    tokenRenewed(state, action: PayloadAction<User | null>) {
      state.user = action.payload;
    }
  }
});

export const { loginSuccess, logoutSuccess, tokenRenewed } = authSlice.actions;

export default authSlice.reducer;
