import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import store from '../../redux-store/store';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { SearchSubmit, PagedNavTable, MpngExportReport, SearchHelpMessage } from '@wne/mpng-components';
import { Paged, Student, StudentSearchRequest } from '../../fetch-services/user.service.types';
import { UserService } from '../../fetch-services/user.service';
import { OrgGroupLevel, Products } from '../../common/enums';
import { getReportFilename } from '../../common/utils';
import { StudentsProps } from './students.props';
import './students.scss';

const emptyPage: Paged<Student> = {
  size: 0,
  page: 0,
  pageSize: 0,
  values: []
};

const pageSizeOptions = [50, 100, 250, 500];

const Students = (props: StudentsProps) => {
  const { orgLevel, orgName, orgGuid } = props;
  const [studentsPagedData, setStudentsPagedData] = React.useState<Paged<Student>>(emptyPage);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
  const [searchText, setSearchText] = React.useState('');
  const [sortColumn, setSortColumn] = React.useState('LastName');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');

  const searchTextRef = useRef('');

  const navigate = useNavigate();

  let studentColumnDetails =
  [
    {
      name: 'Student Name',
      getColumnData: (data: Student) => `${data.lastName}, ${data.firstName}`,
      alignment: 'left',
      sortProperty: 'LastName',
      onLinkSelect: (data: Student) => navigate(`/student/${data.userGuid}`)
    },
    {
      name: 'Username',
      getColumnData: (data: Student) => data.loginName,
      sortProperty: 'LoginName',
      alignment: 'left'
    },
    {
      name: 'School',
      getColumnData: (data: Student) => data.schoolName,
      sortProperty: 'SchoolName',
      alignment: 'left'
    },
    {
      name: 'External ID',
      getColumnData: (data: Student) => data.districtAssignedId,
      alignment: 'left'
    },
    {
      name: 'Grade Level',
      getColumnData: (data: Student) => data.gradeLevelName,
      sortProperty: 'GradeLevelId',
      alignment: 'left'
    }
  ];

  if (orgLevel !== OrgGroupLevel.District) { //remove school column if not in a district
    studentColumnDetails = studentColumnDetails.filter((column) => column.name !== 'School');
  };

  const studentsCSVColumns = [
    {
      label: 'Student Last Name',
      id: 'lastName'
    },
    {
      label: 'Student First Name',
      id: 'firstName'
    },
    {
      label: 'Username',
      id: 'loginName'
    },
    {
      label: 'School',
      id: 'schoolName'
    },
    {
      label: 'External ID',
      id: 'districtAssignedId'
    },
    {
      label: 'Grade Level',
      id: 'gradeLevelName'
    }
  ];

  const getStudentSearchRequest = (): StudentSearchRequest => {
    const request: StudentSearchRequest = {
      searchText: searchText,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      orgGuid: orgGuid,
      grades: [],
      products: [Products.IPA],
      page: page,
      pageSize: pageSize
    };
    return request;
  };

  const fetchStudents = async () => {
    store.dispatch(setActivityIndicator(true));
    const response = await UserService.getStudentsByDistrict(getStudentSearchRequest());
    if (response) {
      setStudentsPagedData(response);
    }
    store.dispatch(setActivityIndicator(false));
  };

  const fetchStudentsForCSV = async (csvCallback: (data: any) => void) => {
    store.dispatch(setActivityIndicator(true));
    const response = await UserService.getStudentsByDistrictCSV(getStudentSearchRequest());
    if (response) {
      csvCallback({rows: response, columns: studentsCSVColumns});
    }
    store.dispatch(setActivityIndicator(false));
  };

  useEffect(() => {
    fetchStudents();
  }, [orgGuid, searchText, sortColumn, sortDirection, page, pageSize]);

  const onSearch = () => {
    setSearchText(searchTextRef.current);
    setPage(1);
  };

  const onClearSearch = (fetchResults: boolean) => {
    if (fetchResults) {
      setPage(1);
      setSearchText('');
    }
    searchTextRef.current = '';
  };

  const onSortChange = (sortProperty: string) => {
    if (sortColumn === sortProperty) {
      setSortDirection((prev) => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(sortProperty);
      setSortDirection('asc');
    }
    setPage(1);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <div className='students-search'>
      <div className='students-search-header'>
        <SearchSubmit 
          placeHolder='Search Student Name, Username'
          className='students-search-bar'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchTextRef.current = e.target.value}
          onSearch={onSearch}
          onClear={onClearSearch}
        />
        <MpngExportReport
          reportName={getReportFilename('Student Roster', orgName)}
          includePDF={false}
          mpngStyle={false}
          csvCallback={fetchStudentsForCSV}
        />
      </div>
      <PagedNavTable
        columnDetails={studentColumnDetails}
        tableData={studentsPagedData.values}
        emptyTableContent={<SearchHelpMessage />}
        tableHeight='calc(100vh - 178px)'
        includePagination={true}
        totalItemsCount={studentsPagedData.size}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        sortChange={onSortChange}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        pageNumber={page}
        pageChange={(page: number) => setPage(page)}
        pageSizeChange={onPageSizeChange}
        className='students-table'
      />
    </div>
  );
};

export default Students;
