import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import { Tabs } from '@wne/mpng-components';
import Students from '../../components/students/students';
import Educators from '../../components/educators/educators';
import { OrgGroupLevel } from '../../common/enums';

const Users = () => {
  const { mainOrg, userInfo } = useSelector((state: RootState) => state.user); //Users tab will always use the main org
  useEffect(() => {
    store.dispatch(setPageTitle('Users'));
  }, []);

  const orgDetails = {
    orgLevel: mainOrg?.orgType.toLowerCase() as OrgGroupLevel ?? '',
    orgName: mainOrg?.name ?? '',
    orgGuid: mainOrg?.orgGuid ?? '',
  }

  const tabsProps = {
    defaultActiveKey: 'students',
    items: [
      {
        title: 'Students',
        eventKey: 'students',
        content: <Students {...orgDetails} />
      },
      {
        title: 'Educators',
        eventKey: 'educators',
        content: <Educators {...orgDetails} userGuid={userInfo?.userGuid ?? ''} />
      }
    ],
    className: 'asmt-users-tabs'
  };

  return (
    <Tabs {...tabsProps} />
  );
};

export default Users;
