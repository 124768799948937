import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import ReportsComponent from '../../components/reports/reports';
import { OrgGroupLevel, Role } from '../../common/enums';
import { Organization } from '../../fetch-services/org.service.types';

const Reports = () => {
  const [org, setOrg] = useState<Organization | null>(null);
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let currentOrg = user.mainOrg;
    // if (user.userInfo?.role === Role.DistrictAdmin && user.selectedOrg) {
    //   currentOrg = user.selectedOrg;
    // }  
    // TODO: use url from parameter instead of main/selected org (selected org has been removed)
    setOrg(currentOrg);
    store.dispatch(setPageTitle(currentOrg?.name || 'Reports'));
  }, [user]);

  return (
    <ReportsComponent
      level={org?.orgType as OrgGroupLevel}
      orgGuid={org?.orgGuid!}
    />
  );
};

export default Reports;
