import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux-store/store';
import { ActivityIndicator } from '@wne/mpng-components';
import Navigation from '../navigation/navigation';
import Formatives from '../mfe-placeholders/formatives';
import DiagnosticReport from '../mfe-placeholders/diagnostic-report';
import { EducatorUiProps } from '../../../../root-config/src/shared/educator-ui.props';
import Institutions from '../institutions/institutions';
import Profile from '../profile/profile';
import Reports from '../reports/reports';
import IpaHome from '../mfe-placeholders/ipa-home';
import AssessmentScheduler from '../mfe-placeholders/assessment-scheduler';
import Home from '../home/home';
import Users from '../users/users';
import StudentProfile from '../student-profile/student-profile';
import EducatorProfile from '../educator-profile/educator-profile';

const Routing = (props: EducatorUiProps) => {
  const { mainOrg } = useSelector((state: RootState) => state.user);
  const { activityIndicator } = useSelector((state: RootState) => state.app);
  return (
    <>
      {activityIndicator && <ActivityIndicator />}
      <BrowserRouter>
        <Routes>
          <Route element={<Navigation />}>
            <Route path='/' element={<Home />} />
            <Route
              path='home'
              element={
                <IpaHome userBehaviorSubject={props.userBehaviorSubject} />
              }
            />
            <Route path='profile' element={<Profile />} />
            <Route path='institutions' element={<Institutions />} />
            <Route path='district/*' element={<div>Districts</div>} />
            <Route path='school/*' element={<div>Schools</div>} />
            <Route path='classes' element={<div>Classes</div>} />
            <Route path='users' element={<Users />} />
            <Route path='students' element={<div>Students</div>} />
            <Route path='student/*' element={<StudentProfile />} />
            <Route path='educator/*' element={<EducatorProfile />} />
            <Route
              path='academicsessions'
              element={<div>Academic Sessions</div>}
            />
            <Route path='settings' element={<div>Settings</div>} />
            {mainOrg && (
              <>
                <Route path='reports' element={<Reports />} />
                <Route
                  path='scheduling'
                  element={
                    <AssessmentScheduler
                      organizationBehaviorSubject={
                        props.organizationBehaviorSubject
                      }
                    />
                  }
                />
                <Route
                  path='assignmentbuilder/*'
                  element={
                    <Formatives
                      formativesBehaviorSubject={props.formativesBehaviorSubject}
                    />
                  }
                />
                <Route
                  path='mypath-assessment-reports/*'
                  element={
                    <DiagnosticReport
                      pageTitleBehaviorSubject={props.pageTitleBehaviorSubject}
                      breadCrumbBehaviorSubject={props.breadCrumbBehaviorSubject}
                    />
                  }
                />
              </>
            )}
            <Route path='*' element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
