import React, { useEffect } from 'react';
import store from '../../redux-store/store';
import {
  setActiveNavItem,
  setPageTitle
} from '../../redux-store/slices/app-slice';
import { SidebarLabel } from '../../common/enums';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from '../../../../root-config/src/shared/educator-ui.props';

export type DiagnosticReportProps = {
  pageTitleBehaviorSubject?: BehaviorSubject<string>;
  breadCrumbBehaviorSubject?: BehaviorSubject<BreadCrumb>;
};

const DiagnosticReport = (props: DiagnosticReportProps) => {
  const { breadCrumbBehaviorSubject } = props;
  store.dispatch(setActiveNavItem(SidebarLabel.Reports));

  useEffect(() => {
    breadCrumbBehaviorSubject?.subscribe((breadCrumb) => {
      if (breadCrumb.crumbs.length > 0) {
        store.dispatch(
          setPageTitle(breadCrumb.crumbs[breadCrumb.crumbs.length - 1].name)
        );
      }
    });
  }, [breadCrumbBehaviorSubject]);

  return <div id="diagnostic-report"></div>;
};

export default DiagnosticReport;
